import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getFirestore, collection, getDocs, where, doc, onSnapshot } from 'firebase/firestore'
import { getStorage, getDownloadURL, ref } from 'firebase/storage'

initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
})

const auth = getAuth()
const db = getFirestore()
const storage = getStorage()

export let globalProperties = {}

onAuthStateChanged(auth, async user => {
  if (user) {
    const querySnapshot = await getDocs(
      collection(db, `customers/${auth.currentUser.uid}/subscriptions`),
      where('status', 'in', ['active', 'trialing'])
    )

    await new Promise(resolve => {
      onSnapshot(doc(db, 'customers', auth.currentUser.uid), async doc => {
        const data = { ...doc.data() }

        const isAdmin = data?.isAdmin
        let isSubscribedUser = querySnapshot?.docs?.length >= 1 || isAdmin
        if(data.accountDisabled) isSubscribedUser = false

        if (data.event) {
          const pictureRef = ref(storage, `EVENTS/${data.event}`)

          await getDownloadURL(pictureRef).then(url => {
            data.event = { name: data.event, url: url }
          })
        }
        globalProperties = { userUid: auth.currentUser.uid, subscribedUser: isSubscribedUser, ...data }
        resolve()
      })
    })
  }

  import(/* webpackChunkName: "createApp" */ './createApp').then(createApp => {
    createApp.createApp()
  })
})
